import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { useQuery } from '@apollo/client'
import { OM_INVESTOR_EMAIL, TrackedButton } from '@flock/shared-ui'
import { Typography } from '@mui/material'
import { InvestorGetPropertyQuestionnaireV2OneTimeLinkDocument } from '@flock/flock-gql-server/src/__generated__/graphql'

import PropertyQuestionnairePage from '../../components/ExchangeComponents/PropertyQuestionnaireComponents/PropertyQuestionnairePage'
import LoadingCardPage from '../../components/SharedComponents/LoadingCardPage'

const SharedPropertyQuestionnaire = (props: { oneTimeLink: string }) => {
  const { oneTimeLink } = props
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [orderUuid, setOrderUuid] = useState('')
  const [propertyContributionUuid, setPropertycontributionUuid] = useState('')

  const { refetch } = useQuery(
    InvestorGetPropertyQuestionnaireV2OneTimeLinkDocument,
    {
      variables: {
        getPropertyQuestionnaireV2OneTimeLinkInput: { link: oneTimeLink },
      },
    }
  )

  const fetchQuestionnaireProps = useCallback(async () => {
    try {
      const result = await refetch()
      const { data: questionnaireProps, error: questionnairePropsError } =
        result
      if (questionnairePropsError) {
        setError(true)
      }
      setOrderUuid(
        questionnaireProps?.getPropertyQuestionnaireV2OneTimeLink
          ?.orderUuid as string
      )
      setPropertycontributionUuid(
        questionnaireProps?.getPropertyQuestionnaireV2OneTimeLink
          ?.propertyContributionUuid as string
      )
    } catch (e) {
      setError(true)
    }
    setLoading(false)
  }, [refetch])

  useEffect(() => {
    fetchQuestionnaireProps()
  }, [fetchQuestionnaireProps])

  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        gap="16px"
        sx={{
          backgroundColor: 'gray1.main',
        }}
      >
        <Typography variant="h4">Oops, your link is invalid.</Typography>
        <Typography variant="p2">
          Please refresh the page and try again or contact us for assistance.
        </Typography>

        <Box display="flex" gap="32px">
          <TrackedButton
            size="small"
            onClick={() => {
              window.open(OM_PHONE_URL)
            }}
          >
            Call {OM_PHONE_NUMBER}
          </TrackedButton>
          <TrackedButton
            size="small"
            onClick={() => window.open(`mailto:${OM_INVESTOR_EMAIL}`)}
          >
            Email {OM_INVESTOR_EMAIL}
          </TrackedButton>
        </Box>
      </Box>
    )
  }
  if (loading) {
    return <LoadingCardPage text="Loading questionnaire..." />
  }

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(235, 241, 241, 1)',
        minHeight: '100vh',
      }}
    >
      <PropertyQuestionnairePage
        unauthed
        orderUuid={orderUuid}
        propertyContributionUuid={propertyContributionUuid}
        oneTimeLink={oneTimeLink}
      />
    </Box>
  )
}

export default SharedPropertyQuestionnaire
